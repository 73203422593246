.ms-w-gallery-grid {
    display: grid; 
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-template-rows: repeat(minmax(500px, 1fr));
    grid-auto-flow: row dense;
}

.ms-w-gallery-grid > div {
    grid-column: span 1;
}

@media only screen and (max-width: 768px) {
    .ms-w-gallery-grid {
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        /*grid-template-rows: 240px;*/
        grid-template-rows: repeat(auto-fit, minmax(240px, 1fr));
    }
}

.ms-w-gallery-showcase-large {
    grid-area: span 2 / span 2 / auto / -1 !important; 
    
    min-height: 480px;
}

.ms-w-gallery-showcase-large.start {
    grid-area: span 2 / 1 / auto / span 2; 
    min-height: 480px;
}

.ms-w-gallery-showcase-medium {
    grid-area: span 1 / span 2 / auto / -1; 
    
    min-height: 480px;
}

.ms-w-gallery-showcase-medium.start {
    grid-area: auto / 1 / span 1 / span 2;
    
    min-height: 480px;
}


.ms-w-gallery-features-scroll {
    border: none !important;
    background: none !important;
}
